import React from 'react'
import Typography from "@mui/material/Typography";

import { Paper, Grid } from "@mui/material";

const ProjectVarianceWidget = (props) => {
    const { variance } = props;
  return (
    <Grid item xl={3}  lg={3} md={4} sm={12} xs={12}>
        <Paper style={{ height:200, width: '100%',overflow: 'auto' }} elevation={8} >
            <div className="flex flex-row flex-wrap items-center justify-center p-12 my-5">
                <Typography className="text-24 font-semibold leading-none tracking-tighter " >
                    Project Variance
                </Typography>
            </div>
            <div className="flex flex-wrap items-center justify-center items-center">
                <Typography className="text-36 font-semibold" sx= {{ textDecoration: 'underline',marginTop: '15px' }}>
                    {variance}
                </Typography>
            </div>
        </Paper>
</Grid>
  )
}

export default ProjectVarianceWidget