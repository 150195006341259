import Typography from "@mui/material/Typography";
import _ from "@lodash";

import { Paper } from "@mui/material";
import  Grid from '@mui/material/Grid';

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const PlannedTimeMonthlyWidget = ({data}) => {
  return (
    <Grid item xl={3}  lg={3} md={4} sm={12} xs={12}>
        <Paper style={{ height:200, width: '100%',overflow: 'auto' }} elevation={8} >
        <div className="flex flex-row flex-wrap items-center justify-center p-12 my-5">
            <Typography className="text-24 font-semibold leading-none tracking-tighter " >
               Planned Time Tasks (Month)
            </Typography>
        </div>
        <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Tasks</TableCell>
                        <TableCell align="center">Variance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        Array.isArray(data) ? data?.map((tasks) =>  (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align="center">{tasks.issue_name}</TableCell>
                              <TableCell align="center">{tasks.status}</TableCell>
                            </TableRow>
                        )) : (
                          <div className="flex flex-wrap items-center justify-center items-center">
                           <Typography className="h3 font-medium pb-10">{data}</Typography>
                          </div>
                        )
                    }
                    </TableBody>
                  </Table>
                </TableContainer>
        </Paper>
    </Grid>
  )
}

export default PlannedTimeMonthlyWidget