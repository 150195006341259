import { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import _ from "@lodash";

import { Paper } from "@mui/material";
import  Grid from '@mui/material/Grid';

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const CurrentSprintActiveTasks = ({data}) => {
    const [activeTasks, setActiveTasks] = useState([]);
    
    useEffect(() => {
        setActiveTasks(data && data.filter( (task) => task.state === 'active' ))
    }, [data])


  return (
    <Grid item xl={3}  lg={3} md={4} sm={12} xs={12}>
        <Paper style={{ height:200, width: '100%',overflow: 'auto' }} elevation={8} >
        <div className="flex flex-row flex-wrap items-center justify-center p-12 my-5">
            <Typography className="text-24 font-semibold leading-none tracking-tighter " >
              Current Sprint Tasks (Active)
            </Typography>
        </div>
        
        <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Tasks</TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        activeTasks?.map((tasks) =>  (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align="center">{tasks.issue_key}</TableCell>
                              <TableCell align="center">{tasks.issue_status}</TableCell>
                            </TableRow>
                        )) 
                    }
                    </TableBody>
                  </Table>
                </TableContainer>
        </Paper>
    </Grid>
  )
}

export default CurrentSprintActiveTasks