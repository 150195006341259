import { authRoles } from "app/auth";
import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "applications",
    title: "Applications",
    translate: "APPLICATIONS",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "dashboards",
        title: "Dashboards",
        translate: "DASHBOARDS",
        type: "collapse",
        icon: "dashboard",
        
        children: [
          {
            id: "analytics-dashboard",
            title: "Analytics",
            type: "item",
            url: "apps/dashboards/analytics",
          },
        ],
      },
      {
        id: "performance-overview",
        title: "Performance Overview",
        translate: "PERFORMANCE_OVERVIEW",
        type: "item",
        icon: "insert_chart_outlined",
        url: "apps/performance-overview",
      },
      {
        id: "track-hours",
        title: "Track Hours",
        translate: "TRACK_HOURS",
        type: "item",
        icon: "access_time",
        url: "apps/track-hours",
      },
      {
        id: "pm-overview",
        title: "PM Overview",
        translate: "PM_OVERVIEW",
        type: "item",
        icon: "groups",
        url: "apps/pm/overview/projects",
      },
      {
        id: "1",
        title: "CRM",
        translate: "CRM",
        type: "collapse",
        icon: "assignment",
        // url: "apps/crm/capacity/request",
        children: [
          {
            id: "monthly-capacity-request",
            title: "Monthly Capacity Request",
            type: "item",
            url: "apps/crm/capacity/request",
          },
          {
            id: "developer-allocation-overview",
            title: "Developer Allocation Overview",
            type: "item",
            url: "apps/crm/capacity/allocation",
          },
        ],
      },
      {
        id: "2",
        title: "Settings",
        translate: "SETTINGS",
        type: "collapse",
        icon: "settings",
        // url: "apps/settings/departments/all",
        children: [
          {
            title: "Departments",
            translate: "Departments",
            type: "collapse",
            // url: "apps/settings/departments/all",
            children: [
              {
                id: "view-departments",
                title: "View All",
                type: "item",
                url: "apps/settings/departments/all",
                end: true,
              },
              {
                id: "add-department",
                title: "Create New",
                type: "item",
                url: "apps/settings/departments/new",
              },
            ],
          },
          {
            title: "Types",
            translate: "Types",
            type: "collapse",
            // url: "apps/settings/types/all",
            children: [
              {
                id: "all-types",
                title: "View All",
                type: "item",
                url: "apps/settings/types/all",
                end: true,
              },
              {
                id: "add-types",
                title: "Create New",
                type: "item",
                url: "apps/settings/types/new",
              },
            ],
          },
          {
            id: "skills",
            title: "Skills",
            type: "collapse",
            // url: "apps/settings/skills/all",
            children: [
              {
                id: "all-types",
                title: "View All",
                type: "item",
                url: "apps/settings/skills/all",
                end: true,
              },
              {
                id: "add-types",
                title: "Create New",
                type: "item",
                url: "apps/settings/skills/new",
              },
            ],
          },
          {
            id: "developer",
            title: "Developer",
            type: "item",
            url: "apps/settings/developer/all",
          },
          {
            id: "user_roles",
            title: "User Roles",
            type: "collapse",
            // url: "apps/settings/userRole/list",
            children: [
              {
                id: "all",
                title: "View All",
                type: "item",
                url: "apps/settings/userRole/list",
                end: true,
              },
              {
                id: "add",
                title: "Create New",
                type: "item",
                url: "apps/settings/userRole/add",
              },
            ],
          },
        ],
      },
      {
        id: "projects",
        title: "Projects",
        translate: "PROJECTS",
        type: "item",
        icon: "folder",
        url: "apps/projects",
      },
      {
        id: "3",
        title: "Usersdsdd",
        translate: "USERS",
        type: "collapse",
        icon: "star",
        // url: "apps/users",
        children: [
          {
            id: "active-users",
            title: "Active Users",
            type: "item",
            url: "apps/users/active",
            end: true,
          },
          {
            id: "inactive-users",
            title: "InActive User",
            type: "item",
            url: "apps/users/inactive",
          },
        ],
      },
    ],
  },
  {
    type: "divider",
    id: "divider-1",
  },
];

export default navigationConfig;
