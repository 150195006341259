import { lazy } from "react";
import { Navigate } from "react-router-dom";

const ActiveHours = lazy(() => import("./track-hours/Track-Hours"));
const ActiveHoursEdit = lazy(() => import("./edit/Edit"));

const TrackHoursAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/track-hours/edit/:trackId",
      element: <ActiveHoursEdit />,
    },
    {
      path: "apps/track-hours",
      element: <ActiveHours />,
    },
  ],
};

export default TrackHoursAppConfig;
