import { lazy } from "react";
import { Navigate } from "react-router-dom";

const CapacityList = lazy(() => import("./capacityOverview/List/list"));
const CapacityAllocationOverView = lazy(() =>
  import("./DeveloperAllocationOverview/index")
);

const CRMAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/crm/capacity/request",
      element: <CapacityList />,
    },
    {
      path: "apps/crm/capacity/allocation",
      element: <CapacityAllocationOverView />,
    },
  ],
};

export default CRMAppConfig;
