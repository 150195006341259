import { lazy } from "react";
import { Navigate } from "react-router-dom";

const PerformanceOverview = lazy(() => import("./Index"));

const PerformanceOverviewConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/performance-overview",
      element: <PerformanceOverview />,
    },
  ],
};

export default PerformanceOverviewConfig;
