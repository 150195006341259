import FuseLoading from "@fuse/core/FuseLoading";
import FusePageCarded from "@fuse/core/FusePageCarded";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import axios from "axios";
import { BASE_URL } from "app/services/config";

import '../style.css'

import { FormControl, InputLabel, ListItem, ListItemButton, ListSubheader, MenuItem, Paper, Select } from "@mui/material";

import  Grid from '@mui/material/Grid';
import Box  from '@mui/material/Box';
import { isEmpty } from "lodash";
import _ from "@lodash";
import { format } from "date-fns";
import DetailHeader from "./DetailHeader";
import TeamWidget from "./widgets/TeamWidget";
import ProjectVarianceWidget from './widgets/ProjectVarianceWidget';
import MonthlyTasksVariance from "./widgets/MonthlyTasksVariance";
import WeeklyTasksVariance from './widgets/WeeklyTasksVariance';
import TrackedTimeMonthWidget from "./widgets/TrackedTimeMonthWidget";
import TrackedTimeWeekWidget from "./widgets/TrackedTimeWeekWidget";
import PlannedTimeWeeklyWidget from "./widgets/PlannedTimeWeeklyWidget";
import PlannedTimeMonthlyWidget from './widgets/PlannedTimeMonthlyWidget';
import CurrentSprintActiveTasks from './widgets/CurrentSprintActiveTasks';
import CurrentSprintResolvedTasks from "./widgets/CurrentSprintResolvedTasks";
import CurrentSprintInProgressTasks from "./widgets/CurrentSprintInProgressTasks";
import CurrentSprintQATasks from "./widgets/CurrentSprintQATasks";
import CurrentSprintTodoTasks from "./widgets/CurrentSprintTodoTasks";
import TasksLongerThanSprint from "./widgets/TasksLongerThanSprint";


const Root = styled(FusePageCarded)(({ theme }) => ({
  "& .FusePageCarded-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: 136,
      height: 136,
    },
  },
  "& .boxWrapper": {
    borderBottom: "1px solid #eeeeee",
    padding: 4,
    "& .label": {
      fontSize: 18,
      minWidth: 146,
      display: "inline-block",
    },
    "& .content": {
      fontSize: 18,
    },
  },
}));

function ViewDetails(props) {
    const { projectId } = useParams();
    const [loading, setLoading] = useState(true);
    const [team, setTeam] = useState([]);
    const [projectVariance, setProjectVariance] = useState('');
    const [monthlyVariance, setMonthlyVariance] = useState(null);
    const [weeklyVariance, setWeeklyVariance] = useState(null);
    const [trackTimeMonthly, setTrackTimeMonthly] = useState(null);
    const [trackTimeWeekly, setTrackTimeWeekly] = useState(null);
    const [planTimeMonthly, setPlanTimeMonthly] = useState(null);
    const [planTimeWeekly, setPlanTimeWeekly] = useState(null);
    const [currentSprintData, setCurrentSprintData] = useState(null);
    const [sprintData, setSprintData] = useState(null);
    


    const [apiDate, setApiDate] = useState(format(new Date(), "MMM-yy"));
    const [week, setWeek] = useState([]);
    const [month, setMonth] =useState([]);
    const [selectedMonth, setSeletedMonth] = useState(apiDate);
    const [selectedWeek, setSelectedWeek] = useState(3);
    

    const handleChangeWeek = (event) => {
      setSelectedWeek(event.target.value);
    };
    const handleChangeMonth = (event) => {
      setSeletedMonth(event.target.value);
    };

  useEffect(() => {
    axios.get(BASE_URL + `/pm-overview/frontend-pm-overview/list-filter-data?id=${projectId}&request_month=${selectedMonth}&week=${selectedWeek}`)
      .then((res) => {
        setTeam(res.data?.data?.project_team);
        setProjectVariance(res.data?.data?.total_time_varaince_of_project);
        setMonthlyVariance(res.data?.data?.total_time_varaince_of_project_task_this_month);
        setWeeklyVariance(res.data?.data?.Weekly_Time_Vaiance_of_Project_Task[`Week-${selectedWeek}`]);
        setTrackTimeMonthly(res.data?.data?.Track_Time_Task_Month_Status);
        setTrackTimeWeekly(res.data?.data?.Track_Time_Task_Week_Status);
        setPlanTimeMonthly(res.data?.data?.Planned_Time_Task_Month_Status);
        setPlanTimeWeekly(res.data?.data?.Planned_Time_Task_Week_Status);
        setCurrentSprintData(res.data?.data?.Current_Sprint_Data);
        setSprintData(res.data?.data?.Sprint_Data);
        setLoading(false);
        
      });
  }, [selectedMonth, selectedWeek]);


  useEffect(() => {
    axios
      .get(
        BASE_URL + "pm-overview/frontend-pm-overview/list-filter-months-year"
      )
      .then((res) => {
        setMonth(res.data?.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BASE_URL + "pm-overview/frontend-pm-overview/list-filter-weeks")
      .then((res) => {
        setWeek(res.data?.data);
        setLoading(false);
      });
  }, []);

 


  if (loading) {
    return <FuseLoading />;
  }

  return (
    
    <Root
      header={<DetailHeader />}
      content={
        <div className="w-full flex flex-col">
          <div className="boxWrapper">
            <Box className="monthSelect" sx={{ minWidth: 120 }}>
              <FormControl fullWidth className="fieldWidth">
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedMonth}
                  label="Month"
                  onChange={handleChangeMonth}
                >
                  {Object.entries(month).map((item) => {
                    return (
                      <MenuItem key={item[0]} value={item[0]}>
                        {item[1]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box className="monthSelect" sx={{ minWidth: 120 }}>
              <FormControl fullWidth className="fieldWidth">
                <InputLabel id="demo-simple-select-label">Weeks</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedWeek}
                  label="Week"
                  onChange={handleChangeWeek}
                >
                  {Object.entries(week).map((item) => {
                    return (
                      <MenuItem key={item[0]} value={item[0]}>
                        {item[1]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="w-full p-20">
          <Grid container spacing={2} justify="center" >
            <TeamWidget team={team} />
            <ProjectVarianceWidget variance={projectVariance} />
            <MonthlyTasksVariance data={monthlyVariance} />
            <WeeklyTasksVariance data={weeklyVariance} />
            <TrackedTimeWeekWidget data={trackTimeWeekly} />
            <TrackedTimeMonthWidget data={trackTimeMonthly} />
            <PlannedTimeWeeklyWidget data={planTimeWeekly} />
            <PlannedTimeMonthlyWidget data={planTimeMonthly} />
            <CurrentSprintActiveTasks data={currentSprintData} />
            <CurrentSprintResolvedTasks data={currentSprintData} />
            <CurrentSprintInProgressTasks data={currentSprintData} />
            <CurrentSprintQATasks data={currentSprintData} />
            <CurrentSprintTodoTasks data={currentSprintData} />
            <TasksLongerThanSprint data={sprintData}/>
          </Grid>
          </div>
        </div>
      }
      innerScroll
    />
  );
}

export default ViewDetails;
