import AnalyticsDashboardAppConfig from "./dashboards/analytics/AnalyticsDashboardAppConfig";
import UsersAppConfig from "./user-management/UsersAppConfig";
import ProjectsAppConfig from "./project-management/ProjectsAppConfig";
import TrackHoursAppConfig from "./track-hours/TrackHoursAppConfig";
import SettingsAppConfig from "./settings/SettingsAppConfig";
import CRMAppConfig from "./CRM/CRMAppConfig";
import PMAppConfig from "./PMOverview/PMAppConfig";
import PerformanceOverviewConfig from "./performanceOverview/PerformanceOverviewConfig";

const appsConfigs = [
  AnalyticsDashboardAppConfig,
  UsersAppConfig,
  ProjectsAppConfig,
  TrackHoursAppConfig,
  SettingsAppConfig,
  CRMAppConfig,
  PMAppConfig,
  PerformanceOverviewConfig,
];

export default appsConfigs;
