import { lazy } from "react";
import { Navigate } from "react-router-dom";

import ViewDetails from './List/ProjectDetails/Details';

const ProjectOverview = lazy(() => import("./List/list"));

const PMAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/pm/overview/projects",
      element: <ProjectOverview />,
    },
    {
      path: "apps/pm/overview",
      element: <Navigate to="projects" />,
    },
    {
      path: "apps/pm/overview/projects/:projectId",
      element: <ViewDetails />,
    },
  ],
};

export default PMAppConfig;
