import { lazy } from "react";
import { Navigate } from "react-router-dom";

const UserDetail = lazy(() => import("./userDetail/UserDetail"));
const InactiveUser = lazy(() => import("./inactive/ActiveUsers"));
const ActiveUsers = lazy(() => import("./active/ActiveUsers"));
const VerifyUser = lazy(() => import("./verifyUser/VerifyUser"));
const ViewUser = lazy(() => import("./viewUser/ViewUser"));

const UsersAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/users/inactive",
      element: <InactiveUser />,
    },
    {
      path: "apps/users/:userId",
      element: <UserDetail />,
    },

    {
      path: "apps/users/view/:userId",
      element: <ViewUser />,
    },
    {
      path: "apps/users/verify/:userId",
      element: <VerifyUser />,
    },
    {
      path: "apps/users/active",
      element: <ActiveUsers />,
    },
    {
      path: "apps/users",
      element: <Navigate to="active" />,
    },
  ],
};

export default UsersAppConfig;
