import { lazy } from "react";
import { Navigate } from "react-router-dom";

const UserDetail = lazy(() => import("./userDetail/UserDetail"));
const Types = lazy(() => import("./types/Types"));
const AllTypes = lazy(() => import("./type/listTypes/list"));
const ViewTypes = lazy(() => import("./type/viewTypes/View"));
const NewTypes = lazy(() => import("./type/addTypes/Create"));
const EditTypes = lazy(() => import("./type/EditTypes/Edit"));

const AllDepartments = lazy(() => import("./departments/listDepartments/Departments"));
const ViewDepartments = lazy(() => import("./departments/viewDepartments/ViewDepartments"));
const NewDepartments = lazy(() => import("./departments/addDepartments/Create"));
const EditDepartments = lazy(() => import("./departments/EditDepartments/Edit"));

const AllSkills = lazy(() => import("./skills/listSkills/list"));
const NewSkills = lazy(() => import("./skills/addSkills/Create"));
const EditSkills = lazy(() => import("./skills/editSkills/Edit"));

const AllDeveloper = lazy(() => import("./developer/listDeveloper/list"));

const ListUserRole = lazy(() => import("./userRole/list/UserRole"));
const AddUserRole = lazy(() => import("./userRole/add/Create"));
const EditUserRole = lazy(() => import("./userRole/Edit/Edit"));
const ViewUserRole = lazy(() => import("./userRole/view/View"));

const SettingsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/settings/Types",
      element: <Types />,
    },
    {
      path: "apps/users/:orderId",
      element: <UserDetail />,
    },

    {
      path: "apps/settings/userRole/edit/:userRoleId",
      element: <EditUserRole />,
    },
    {
      path: "apps/settings/userRole/view/:userRoleId",
      element: <ViewUserRole />,
    },
    {
      path: "apps/settings/userRole/add",
      element: <AddUserRole />,
    },
    {
      path: "apps/settings/userRole/list",
      element: <ListUserRole />,
    },

    {
      path: "apps/settings/developer/all",
      element: <AllDeveloper />,
    },

    {
      path: "apps/settings/skills/edit/:skillId",
      element: <EditSkills />,
    },
    {
      path: "apps/settings/skills/new",
      element: <NewSkills />,
    },
    {
      path: "apps/settings/skills/all",
      element: <AllSkills />,
    },


    {
      path: "apps/settings/types/edit/:typeId",
      element: <EditTypes />,
    },
    {
      path: "apps/settings/types/view/:typeId",
      element: <ViewTypes />,
    },
    {
      path: "apps/settings/types/new",
      element: <NewTypes />,
    },
    {
      path: "apps/settings/types/all",
      element: <AllTypes />,
    },


    {
      path: "apps/settings/departments/edit/:departmentId",
      element: <EditDepartments />,
    },
    {
      path: "apps/settings/departments/view/:departmentId",
      element: <ViewDepartments />,
    },
    {
      path: "apps/settings/departments/new",
      element: <NewDepartments />,
    },
    {
      path: "apps/settings/departments/all",
      element: <AllDepartments />,
    },
    {
      path: "apps/settings",
      element: <Navigate to="all" />,
    },
  ],
};

export default SettingsAppConfig;
