import Typography from "@mui/material/Typography";
import _ from "@lodash";

import { Paper } from "@mui/material";
import  Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const TasksLongerThanSprint = ({ data }) => {
    const [filterData, setFilterData] = useState(null);
    const resultArray = [];

    useEffect(() => {
        if(data != null){
            const uniquekeys = uniqueIssueKeys(data);
            for (let i = 0; i < uniquekeys.length; i++) {
              resultArray.push(getObject(data, uniquekeys[i]))
            }
            setFilterData(resultArray.filter(d => d.count !== 1))
            console.log(filterData)
        }
    },[data])
    
        //  one function will give unique values
      const uniqueIssueKeys = (data) => {
        let set = new Set;
        for (let i = 0; i < data.length; i++) {
          !set.has(data[i].issue_key) && set.add(data[i].issue_key);
        }
        return Array.from([...set]);
      }
    
      // one function will give object
      const getObject = (data, issue_key) => {
          let count = 0;
          let issue_status = '';
            for (const obj of data) {
              if(obj['issue_key'] === issue_key){
                count++;
                issue_status = obj['issue_status']
              }
            }
    
          return { issue_key, issue_status, count }
      }

  return (
    <Grid item xl={3}  lg={3} md={4} sm={12} xs={12}>
        <Paper style={{ height:200, width: '100%',overflow: 'auto' }} elevation={8} >

              <div className="flex flex-row flex-wrap items-center justify-center p-12 my-5">
                  <Typography className="text-24 font-semibold leading-none tracking-tighter " >
                    Tasks Longer than Sprint
                  </Typography>
              </div>

              <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Tasks</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Weeks</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        Array.isArray(filterData) ? filterData?.map((tasks) =>  (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align="center">{tasks.issue_key}</TableCell>
                              <TableCell align="center">{tasks.issue_status}</TableCell>
                              <TableCell align="center">{tasks.count}</TableCell>
                            </TableRow>
                        )) : (
                          <div className="flex flex-wrap items-center justify-center items-center">
                           <Typography className="h3 font-medium pb-10">{filterData}</Typography>
                          </div>
                        )
                    }
                    </TableBody>
                  </Table>
                </TableContainer>
               
        </Paper>
    </Grid>
  )
}

export default TasksLongerThanSprint