import Typography from "@mui/material/Typography";
import _ from "@lodash";

import { Paper } from "@mui/material";
import  Grid from '@mui/material/Grid';

const WeeklyTasksVariance = ({data}) => {
  return (
    <Grid item xl={3}  lg={3} md={4} sm={12} xs={12}>
        <Paper style={{ height:200, width: '100%',overflow: 'auto' }} elevation={8} >
        <div className="flex flex-row flex-wrap items-center justify-center p-12 my-5">
            <Typography className="text-24 font-semibold leading-none tracking-tighter " >
               Time Variance (Tasks Week)
            </Typography>
        </div>
        {Array.isArray(data) ? data?.map((tasks) => {
            return (
                <div className="p-35 pb-0">
                    <Typography className="text-18 font-medium flex flex-wrap items-center justify-center items-center">
                        
                        { tasks?.task_name === undefined ? '' : tasks?.task_name }
                        {/* { (task_time_variance in tasks) ? + " - " + tasks.task_time_variance : '' } */}
                        
                        
                    </Typography>
                </div>)
            }) : <div className="flex flex-wrap items-center justify-center items-center">
                <Typography className="h3 font-medium pb-10">{data}</Typography>
                </div>
        }
        </Paper>
    </Grid>
  )
}

export default WeeklyTasksVariance