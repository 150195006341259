import Typography from "@mui/material/Typography";
import _ from "@lodash";

import { ListItem, ListItemButton, ListSubheader, Paper } from "@mui/material";
import  Grid from '@mui/material/Grid';

const TeamWidget = (props) => {
  const { team } = props;
  return (
    <Grid item xl={3}  lg={3} md={4} sm={12} xs={12}>
        <Paper style={{ height:200, width: '100%',overflow: 'auto' }} className="sm:fullWidth" elevation={8} >
        <div className="flex flex-row flex-wrap items-center justify-center p-12 my-5">
            <Typography className="text-24 font-semibold leading-none tracking-tighter " >
                Project Teams
            </Typography>
        </div>
        {typeof team === 'object' ? Object.keys(team).map((t) => {
                return (
                    <div>
                    <div className="flex flex-wrap items-center justify-center items-center">
                        <Typography className="text-18 font-semibold" sx= {{ textDecoration: 'underline' }}>{t}</Typography>
                    </div>
                        {team[t].map((dev) => (
                            <div className="p-35 pb-0">
                                <Typography className="text-16 font-medium" pl={4}>
                                    {dev.user_name + " - " + dev.capacity + "h"}
                                </Typography>
                
                            </div>
                        ))}
                    </div>
                )
            }) : (
                <div className="flex flex-wrap items-center justify-center items-center">
                    <Typography className="h3 font-medium pb-10">{team}</Typography>
                </div>
            ) }
    

        </Paper>
    </Grid>
  )
}

export default TeamWidget