import Error404PageConfig from "./errors/404/Error404PageConfig";
import Error500PageConfig from "./errors/500/Error500PageConfig";
import ProfilePageConfig from "./profile/ProfilePageConfig";

const pagesConfigs = [
  Error404PageConfig,
  Error500PageConfig,
  ProfilePageConfig,
];

export default pagesConfigs;
