import { lazy } from "react";
import { Navigate } from "react-router-dom";

const ProjectDetail = lazy(() => import("./viewProject/ViewProject"));
const ActiveProjects = lazy(() => import("./active/ActiveProjects"));
const ProjectEdit = lazy(() => import("./Edit/Edit"));
const NewProject = lazy(() => import("./create/Create"));


const ProjectsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/projects/view/:projectId",
      element: <ProjectDetail />,
    },
    {
      path: "apps/projects/edit/:projectId",
      element: <ProjectEdit />,
    },
    {
      path: "apps/projects/add",
      element: <NewProject />,
    },
    {
      path: "apps/projects",
      element: <ActiveProjects />,
    },
  ],
};

export default ProjectsAppConfig;
